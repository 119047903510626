import React from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import { getStructuredBreadCrumbs } from 'lib/json-ld/getStructuredBreadCrumbs'
import JSONLD from 'components/JSONLD'
import Content from 'views/cremation/public/FuneralOverview/Content'
import {
  FORMATTED_CREMATION_FUNERAL_PLAN_FEE,
  FORMATTED_DIRECT_CREMATION_PRICE,
} from 'views/cremation/shared/constants'
import PATHS from 'paths'

const pageTitle =
  'Funeral Services - Arrange a meaningful funeral with Farewill'
const pageDescription = `Arrange a funeral from ${FORMATTED_DIRECT_CREMATION_PRICE} with Farewill Funerals, or plan ahead with a prepaid funeral plan for just ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE}. Simple, respectful direct cremations from an award-winning team.`

const FuneralOverview = (): React.ReactElement => (
  <Wrapper background={COLOR.WHITE}>
    <JSONLD data={[getStructuredBreadCrumbs({})]} />
    <DefaultLayout
      title={pageTitle}
      description={pageDescription}
      product={PRODUCTS.FUNERAL}
      headerColor={COLOR.WHITE}
      headerType="compact"
      headerFarewillLogoLink={PATHS.GENERAL.HOME}
      footerType="compact"
      footerShowStandardisedPriceList
      footerShowFuneralPlansLegalInformation
      meta={[
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ]}
    >
      <Content />
    </DefaultLayout>
  </Wrapper>
)

export default FuneralOverview
